import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import ImageAbout from "../components/imageAbout"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "rgba(255,255,255,0)",
    textAlign: "center",
    color: "black",
  },
  image: {
    width: 350,
    height: 350,
  },
}))

export default function CenteredGrid() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="space-evenly">
        <Grid item xs={12} sm={5}>
          <div className={classes.image}>
            <ImageAbout />
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="body2">
            Hi! I am Flo and I am a French filmmaker. I love travelling,
            exploring different places and cultures and meeting new people. I
            quit my job to follow my dream, to make my life an adventure. My
            country is not just France anymore, it's the world! But above all,
            my passion is to create and narrate a story through my videos. When
            I film, I feel free. Capturing the spontaneity of the moments and
            making them last forever fascinates me. The editing process is my
            playground, making feelings flow through a complex simplicity.
          </Typography>
          <Typography variant="body2">
            I am ready to film your special moment like a wedding and an
            anniversary or to promote your enterprise. Do not hesitate to
            contact me to discuss your projects !!
          </Typography>
          <br />
          <Typography variant="body2">
            p.s. You can follow me through my journeys on the ‘Travel with me’
            section!
          </Typography>
          <br />
          <Typography variant="body2">See you soon,</Typography>
          <Typography variant="body2">Flo</Typography>
        </Grid>
      </Grid>
    </div>
  )
}
